import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"

import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"
import VideosGrid from "../components/VideosGrid"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"
import SEO from "../components/seo"
import placeholderspeakerimage from "../berlinbear.png"
import YouTubeIcon from '@material-ui/icons/YouTube';
import Box from "@material-ui/core/Box"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: ".2em",
  },
  backButton: {
    textDecoration: "none",
  },
  flexBoxParentDiv: {
    display: "flex",
    width: "1000vh",
    height: "1000vh",
    flexDirection: "row",
    flexWrap: "wrap",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    "&::after": {
      content: "",
      flex: "auto",
    },
  },
  channelNameChips: {
    marginLeft: 10,
    background: "none",
  },

  avatar: {
    margin: 10,
    width: 70,
    height: 70,
  },
  ListItemParentDiv: {
    display: "flex",
  },
  CircularProgressDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  CircularProgressItself: {
    height: "75px !important",
    width: "75px !important",
    textAlign: "center",
  },
  SelectFilters: {
    width: "100%",
  },
  filtersGridParent: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filteredItemsLength: {
    textAlign: "left",
    fontWeight: 400,
  },
    GridParent: {
    padding: 20,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  linkToInstaProf:{
    color:"#2D328E"
  },
  flexBoxExploreSort: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    }
  }
})



const ChannelPage = ({ data, classes, pageContext }) => {
  const itemsWithchannelName = data.allItem.edges


 
  return (

    
      <div className={classes.root}>
          <SEO title={itemsWithchannelName[0].node.channelName} />
        <Grid container spacing={8} className={classes.GridParent}>
          <Grid item lg={8} md={8} xs={12}>
            {itemsWithchannelName.length < 1 ? (
              <div className={classes.CircularProgressDiv}>
                {" "}
                <CircularProgress
                  className={classes.CircularProgressItself}
                />{" "}
              </div>
            ) : (
              <VideosGrid locations={itemsWithchannelName} />
            )}
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
          <Box
           
  
           className={classes.flexBoxExploreSort}
         >
            <Typography
              className={classes.filteredItemsLength}
              variant="title"
              component="h4"
            >
              {itemsWithchannelName.length} Videos by  {" "}

              <a
                 className={classes.linkToInstaProf}
                target="_blank"
                href={
                  "https://www.youtube.com/channel/" +
                  itemsWithchannelName[0].node.channelId + '/videos'
                }
              >
               {itemsWithchannelName[0].node.channelName}   
              </a>
          
  
      
             </Typography> 

     

             <YouTubeIcon style={{color:"red"}} /> 
   

             </Box>

           

 
          </Grid>
        </Grid>

        </div>


  )
}

export default withStyles(styles)(ChannelPage)

export const ChannelPagePageQuery = graphql`
  query ChannelPage($channelName: String) {
    site {
      siteMetadata {
        title
      }
    }
    allItem(
      filter: { channelName: { in: [$channelName] } }
      sort: { fields: view_count, order: DESC }
    ) {
      totalCount
      edges {
        node {
          thumbnail_link
     
          title
          tags
          publishedAt
          channelName
          channelId
          description
          video_id 
          view_count
           
          
        }
      }
    }
  }
`
